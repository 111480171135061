<template xmlns="http://www.w3.org/1999/html">
  <div class="list-wrapper-document acts-component">
    <div class="title-number__list">
      <div class="data-number">
        {{ localeText.myDocsYearNum }}
      </div>
      <div class="name-list">
        {{ localeText.myDocsName }}
      </div>
    </div>
    <div class="container-list">
      <div
        v-for="item in items"
        :key="item.id"
        class="item-list__document"
      >
        <div class="item-list__document-info">
          <div class="item-list__extension">
            <div
              v-if="item.additions?.length > 0"
              class="rounded border-solid border-blue flex justify-center items-center cursor-pointer item-list__extension-btn"
              :style="{
                transform: openAdditionsIds.includes(item.id)
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)'
              }"
              @click="toggleAddition(item.id)"
            >
              <arrow-dropdown-icon />
            </div>
          </div>
          <div class="item-list__number">
            <p>{{ item.year }}</p>
            <span>{{ getItemNumber(item.number) }}</span>
          </div>
          <div class="item-list__content">
            <span>{{ item.type }}</span>
            <a
              v-if="item.is_expelled"
              class="item-list__expelled"
            >
              {{ localeText.docListDissolved }}
            </a>
            <a
              v-if="item.file_url_sign"
              class="item-list__content-tag"
              :href="item.file_url_sign"
              :download="getNameByPath(item.file_url_sign)"
              target="_blank"
            >
              {{ localeText.myDocsSignedDigital }}
            </a>
            <p>
              {{ item.description }}
            </p>
          </div>
          <div class="item-list__buttons gap-1 sm:gap-2">
            <a
              class="link-btn-upload"
              target="_blank"
              :title="localeText.myDocsUploadRec"
              @click.prevent="openUploadForm(item.originalId)"
            />
            <a
              v-show="item.can_sign"
              class="link-btn-upload scancopy"
              target="_blank"
              :title="localeText.attachScanCopy"
              @click.prevent="
                openAttachScanCopyDialog(
                  item.contract_id || item.originalId,
                  item.sign_template
                );
                actId = item.contract_id ? item.originalId : null;
              "
            />
            <!--            <a-->
            <!--              v-if="item.file_url"-->
            <!--              href="javascript:void(0)"-->
            <!--              class="link-btn-share"-->
            <!--              @click="copyToClipboard(item.file_url)"-->
            <!--            >-->
            <!--              {{ localeText.btnShare }}-->
            <!--            </a>-->
            <button
              class="el-button btn el-button--default flex flex-nowrap mr-4"
              :title="localeText.myDocsPaymentSchedule"
              @click="onShowModalPay(item.id)"
            >
              <span class="mr-2 hidden sm:inline-block"><icon-pay-card /></span>
              {{ localeText.docPay }}
            </button>

            <button
              class="button !mr-0"
              @click="onShowModalInfo(item.originalId)"
            />

            <button
              class="button link-btn-download"
              @click="onShowModalFiles(item.id)"
            />

            <!--          <a-->
            <!--            v-show="item.url"-->
            <!--            class="link-btn-download"-->
            <!--            :href="item.url"-->
            <!--            :download="item.file_path"-->
            <!--          />-->
            <!--            @click="downloadDoc(item.url, item.file_path)"-->
          </div>
        </div>
        <div
          v-if="
            item.additions?.length > 0 && openAdditionsIds.includes(item.id)
          "
          class="item-list__document-additions"
        >
          <div
            v-for="addItem in item.additions"
            :key="addItem.id"
            class="item-list__document-additions-item"
          >
            <div class="item-list__document-additions-item-date">
              {{ parseDate(addItem.date) }}
            </div>
            <div
              class="item-list__document-additions-item-title"
              style="flex-grow: 1"
            >
              <a
                v-if="addItem.file_url_sign"
                class="item-list__content-tag"
                :href="addItem.file_url_sign"
                :download="getNameByPath(addItem.file_url_sign)"
                target="_blank"
              >
                {{ localeText.myDocsSignedDigital }}
              </a>
              {{ localeText.myDocsAdditionAgree }} №{{ addItem.number }}
            </div>
            <a
              v-show="addItem.can_sign"
              class="link-btn-upload scancopy"
              style="height: 20px; width: 20px; margin-right: 16px"
              target="_blank"
              :title="localeText.attachScanCopy"
              @click.prevent="
                openAttachScanCopyDialog(
                  item.originalId,
                  addItem.sign_template
                );
                additionalId = addItem.id;
              "
            />
            <a
              v-if="addItem.file_url"
              :href="addItem.file_url"
              class="block"
              target="_blank"
            >
              <download-file-icon style="width: 20px; height: 20px" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="localeText.detailedInfo"
      :visible.sync="showModalInfo"
      class="list-wrapper-document__dialog"
    >
      <detailed-information :items="detailInfoItems" />
      <button
        type="button"
        class="el-button el-button--primary el-button--large"
        style="margin-top: 20px"
        @click="showRequestChanges"
      >
        <span> {{ localeText.requestChanges }} </span>
      </button>
    </el-dialog>
    <el-dialog
      :title="localeText.myDocsPaymentSchedule"
      :visible.sync="showModalPay"
      class="acts-component__dialog-payment"
      top="6vh"
    >
      <payment-information
        v-if="selectedDocItem"
        :localization="localization"
        :contract-num="selectedDocItem.number"
        :contract-id="selectedDocItem.originalId"
        :description="selectedDocItem.description"
        :cost="selectedDocItem.price"
        :modal-visible="showModalPay"
        :pay-button="selectedDocItem.can_pay && selectedDocItem.need_pay"
        @click-pay-btn="onClickPayBtn"
      />
    </el-dialog>
    <el-dialog
      :title="localeText.myDocsUploadRec"
      :visible.sync="showPopup"
      :before-close="clearUploadForm"
      :destroy-on-close="true"
    >
      <form
        ref="uploadForm"
        action=""
      >
        <input
          v-model="uploadId"
          type="hidden"
          name="contract_id"
        />
        <label for="file_receipt">
          <input-file
            id="file_receipt"
            :value="uploadFile"
            :label="localeText.attachFile"
            :show-alert-info="true"
            :accept-extensions="[
              'pdf',
              'doc',
              'docx',
              'rtf',
              'odt',
              'jpg',
              'png',
              'webp'
            ]"
            @on-change="uploadFileHandler"
          />
        </label>
      </form>
      <el-button
        :disabled="!validated"
        :loading="uploadLoading"
        type="primary"
        style="margin-top: 20px"
        @click="submitUploadForm"
      >
        {{ localeText.toSend }}
      </el-button>
    </el-dialog>
    <el-dialog
      class="hse-modal hse-modal__profile-modal hse-modal__indent-y_xl"
      :title="localeText.attachScanCopy"
      :visible.sync="showAttachScanCopyDialog"
      :destroy-on-close="true"
      :before-close="clearUploadScanCopyForm"
    >
      <p class="hse-modal__template-info">
        {{ localeText.myDocsTemplateInfo }}
      </p>
      <a
        class="hse-modal__template-link"
        target="_blank"
        download
        :href="scanCopyFileUrl"
      >
        <span class="hse-modal__template-link-icon-clip" />
        <span>
          {{ localeText.myDocsAgreeTemplate }}
        </span>
        <span class="hse-modal__template-link-icon-import" />
      </a>
      <input
        v-model="uploadId"
        type="hidden"
        name="contract_id"
      />
      <input-file
        id="file"
        :value="uploadScanCopyFile"
        :show-alert-info="true"
        :label="localeText.attachFile"
        :accept-extensions="['pdf', 'jpg', 'png']"
        @on-change="uploadScanCopyFileHandler"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="!validatedScanCopy"
          :loading="uploadLoading"
          type="primary"
          style="margin-top: 20px"
          @click="submitUploadScanCopyForm"
        >
          {{ localeText.toSend }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="localeText.agreementsActs"
      :visible.sync="showModalFiles"
      class="list-wrapper-document__dialog"
      top="6vh"
    >
      <ul>
        <div
          v-for="file of selectedDocItem?.files"
          :key="`file_${file.id}_${selectedDocItem.originalId}`"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            grid-gap: 4px;
          "
        >
          <a
            v-if="file.unsigned_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.unsigned_file.url"
            target="_blank"
            :download="file.unsigned_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">{{
              file.is_document
                ? localeText.labelDocFile
                : localeText.labelAppFile
            }}</span>
            <span class="hse-modal__template-link-icon-import" />
          </a>

          <a
            v-if="file.signature_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.signature_file.url"
            target="_blank"
            :download="file.signature_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">{{
              file.is_document
                ? localeText.labelDocSignature
                : localeText.labelAppSignature
            }}</span>
            <span class="hse-modal__template-link-icon-import" />
          </a>

          <a
            v-if="file.signed_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.signed_file.url"
            target="_blank"
            :download="file.signed_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">
              {{ localeText.localDocECP }}
            </span>
            <span class="hse-modal__template-link-icon-import" />
          </a>
        </div>
      </ul>
    </el-dialog>
    <select-payment-options-dialog
      ref="pay_dialog"
      :loading="sendingPaymentRequest"
      @submit="submitPaymentForm"
    />
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import {ORDER_PAY, V1_CONTRACTS_PAYMENT, V1_CONTRACTS_SCAN_COPY} from '@/api';
import InputFile from '@/components/inputComponent/InputFile';
import PaymentInformation from '@/components/listDocuments/DocumentsPrograms/common/PaymentInformation';
import ArrowDropdownIcon from '@/assets/icons/arrow-dropdown.svg?inline';
import DownloadFileIcon from '@/assets/icons/icon-download-file.svg?inline';
import IconPayCard from '@/assets/icons/icon-pay-card-white.svg?inline';
import {format, parseISO} from 'date-fns';
import setNotification from '@/utils/setNotification';
import SelectPaymentOptionsDialog from '@/components/modal/SelectPaymentOptionsDialog.vue';
import DetailedInformation from '@/components/listDocuments/DocumentsPrograms/common/DetailedInformation.vue';
import {REFERENCEPAGE} from '@/router/routes';
export default {
  name: 'DocListComponent',
  components: {
    // ChangeRequestForm,
    DetailedInformation,
    SelectPaymentOptionsDialog,
    InputFile,
    ArrowDropdownIcon,
    DownloadFileIcon,
    IconPayCard,
    PaymentInformation
  },
  props: {
    items: {type: Array, default: () => []},
    localization: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showModalInfo: false,
      showPopup: false,
      showModalPay: false,
      showAttachScanCopyDialog: false,
      uploadScanCopyFile: {
        $model: []
      },
      additionalId: null,
      actId: null,
      uploadId: null,
      scanCopyFileUrl: null,
      uploadFile: {
        $model: []
      },
      uploadLoading: false,
      openAdditionsIds: [],
      selectedId: -1,
      sendingPaymentRequest: false,
      pay: {
        contract: null,
        payId: null
      },
      localeText: {
        docReceivedYear: this.localization.widgetCaption('docReceivedYear'),
        requestChanges: this.localization.widgetCaption('requestChanges'),
        docPay: this.localization.widgetCaption('docPay'),
        docType: this.localization.widgetCaption('docType'),
        docTypeName: this.localization.widgetCaption('docTypeName'),
        docNumber: this.localization.widgetCaption('docNumber'),
        myDocsDocument: this.localization.widgetCaption('myDocsDocument'),
        detailedInfo: this.localization.widgetCaption('detailedInfo'),
        myDocsYearNum: this.localization.widgetCaption('myDocsYearNum'),
        myDocsName: this.localization.widgetCaption('myDocsName'),
        myDocsSignedDigital: this.localization.widgetCaption(
          'myDocsSignedDigital'
        ),
        myDocsUploadRec: this.localization.widgetCaption('myDocsUploadRec'),
        btnShare: this.localization.widgetCaption('btnShare'),
        myDocsPaymentSchedule: this.localization.widgetCaption(
          'myDocspaymentSchedule'
        ),
        myDocsAdditionAgree: this.localization.widgetCaption(
          'myDocsAdditionAgree'
        ),
        myDocsTemplateInfo:
          this.localization.widgetCaption('myDocsTemplateInfo'),
        attachScanCopy: this.localization.widgetCaption('attachScanCopy'),
        shareNotify: this.localization.widgetCaption('shareNotify'),
        attachNotify: this.localization.widgetCaption('attachNotify'),
        receiptNotify: this.localization.widgetCaption('receiptNotify'),
        docListDissolved: this.localization.widgetCaption('docListDissolved'),
        myDocsAgreeTemplate: this.localization.widgetCaption(
          'myDocsAgreeTemplate'
        ),
        attachFile: this.localization.commonCaption('attachFile'),
        toSend: this.localization.commonCaption('toSend')
      },
      showModalFiles: false,
      docId: null
    };
  },

  computed: {
    validated() {
      return this.uploadFile.$model.length > 0;
    },
    validatedScanCopy() {
      return this.uploadScanCopyFile.$model.length > 0;
    },
    selectedDocItem() {
      return this.items.find(e => e.id === this.selectedId);
    },
    selectedDocItemOriginalId() {
      return this.items.find(e => e.originalId === this.selectedId);
    },
    detailInfoItems() {
      const result = [];
      if (this.selectedDocItemOriginalId) {
        if (this.selectedDocItemOriginalId.year) {
          result.push({
            name: this.localeText.docReceivedYear,
            value: this.selectedDocItemOriginalId.year
          });
        }
        if (this.selectedDocItemOriginalId?.number) {
          result.push({
            name: this.localeText.docNumber,
            value: this.selectedDocItemOriginalId?.number
          });
        }
        if (this.selectedDocItemOriginalId?.type) {
          result.push({
            name: this.localeText.docType,
            value: this.selectedDocItemOriginalId?.type
          });
        }
        if (this.selectedDocItemOriginalId?.type_name) {
          result.push({
            name: this.localeText.docTypeName,
            value: this.selectedDocItemOriginalId?.type_name
          });
        }
      }
      return result;
    }
  },
  mounted() {
    this.$store.dispatch('services/init', {
      type: this.isCorp ? 'corp' : ''
    });
  },

  methods: {
    showRequestChanges() {
      let id =
        this.$store.getters['services/getServiceIdByCode']('project_agreement');
      this.$router.push(`${REFERENCEPAGE}/${id}`);
    },
    onShowModalInfo(id) {
      this.selectedId = id;
      this.showModalInfo = true;
    },
    openAddition(id) {
      if (this.openAdditionsIds.includes(id)) {
        return;
      }
      this.openAdditionsIds.push(id);
    },
    closeAddition(id) {
      this.openAdditionsIds = this.openAdditionsIds.filter(e => e !== id);
    },
    toggleAddition(id) {
      if (this.openAdditionsIds.includes(id)) {
        this.closeAddition(id);
      } else {
        this.openAddition(id);
      }
    },

    parseDate(date) {
      const dateFormat = 'dd.MM.yyyy';
      return format(parseISO(date), dateFormat) ?? '';
    },
    getItemNumber: number => (number ? `№ ${number}` : ''),
    downloadDoc(uri, name) {
      let link = document.createElement('a');
      link.download = name;
      link.href = uri;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    getNameByPath(path) {
      if (!path) {
        return 'document';
      }
      return path.split('/').at(-1) ?? 'document';
    },
    uploadScanCopyFileHandler(files) {
      this.uploadScanCopyFile = {
        $model: files
      };
    },
    uploadFileHandler(files) {
      this.uploadFile = {
        $model: files
      };
    },
    openUploadForm(id) {
      this.showPopup = true;
      this.uploadId = id;
    },
    openAttachScanCopyDialog(id, url) {
      this.showAttachScanCopyDialog = true;
      this.uploadId = id;
      this.scanCopyFileUrl = url;
    },
    async submitUploadForm() {
      const fd = new FormData();
      fd.append('contract_id', this.uploadId);
      fd.append('file', this.uploadFile.$model[0]);
      this.uploadLoading = true;
      try {
        await Axios.post(V1_CONTRACTS_PAYMENT, fd, {
          headers: {'Content-Type': 'multipart/form-data'}
        });
        setNotification({
          message: this.localeText.receiptNotify,
          type: 'success'
        });
        this.$emit('refresh');
      } catch (e) {
        if (e.response?.data?.message) {
          setNotification({message: e.response?.data?.message});
        }
      }
      this.uploadLoading = false;

      this.clearUploadForm();
    },
    async submitUploadScanCopyForm() {
      const formData = new FormData();
      formData.append('file', this.uploadScanCopyFile.$model[0]);
      formData.append('contract_id', this.uploadId);
      if (this.actId) {
        formData.append('act_id', this.actId);
      } else if (this.additionalId) {
        formData.append('additional_id', this.additionalId);
      }
      this.uploadLoading = true;
      try {
        await Axios.post(V1_CONTRACTS_SCAN_COPY, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        });
        setNotification({
          message: this.localeText.attachNotify,
          type: 'success'
        });
        this.$emit('refresh');
      } catch (e) {
        if (e.response?.data?.message) {
          setNotification({message: e.response?.data?.message});
        }
      }
      this.uploadLoading = false;

      this.clearUploadScanCopyForm();
    },
    clearUploadScanCopyForm() {
      this.showAttachScanCopyDialog = false;
      this.uploadScanCopyFile = {
        $model: []
      };
      this.uploadId = null;
      this.scanCopyFileUrl = null;
      this.additionalId = null;
      this.actId = null;
    },
    clearUploadForm() {
      this.showPopup = false;
      this.uploadFile = {
        $model: []
      };
      this.uploadId = null;
    },
    copyToClipboard(url) {
      navigator.clipboard.writeText(url);
      setNotification({
        message: this.localeText.shareNotify,
        type: 'success'
      });
    },
    onShowModalPay(id) {
      this.selectedId = id;
      this.showModalPay = true;
    },
    onClickPayBtn(contractId, payId) {
      this.pay.contract = this.items.find(i => (i.originalId = contractId));
      this.pay.payId = payId;
      this.$refs.pay_dialog.show();
    },
    async submitPaymentForm(paymentOption) {
      this.sendingPaymentRequest = true;

      const {data} = await Axios.post(ORDER_PAY, {
        //order_id: this.pay.contract.order_id,
        contract_id: this.pay.contract.originalId,
        payment_method: paymentOption,
        payment_id: this.pay.payId
      });

      if (data?.code === 200) {
        const url = data.data.payment_url;

        if (url) {
          window.location.replace(url);
        }
      }

      this.sendingPaymentRequest = false;
      this.$refs.pay_dialog.hide();
    }
  }
};
</script>

<style lang="scss">
.btn_pay {
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 20px;
    position: relative;
    top: -2px;
  }
}
.item-list__expelled {
  display: inline-block;
  border: 1px solid rgb(240, 242, 245);
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  margin: 0 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: rgb(107, 122, 153);
}
.btn.el-btn__inform:before {
  content: '';
  -webkit-mask-image: url('@/assets/icons/sign-act-icon.svg');
  mask-image: url('@/assets/icons/sign-act-icon.svg');
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  transition: background-color 200ms ease;
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  background: $dusk-gray-color;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.btn.el-btn__inform:hover:before {
  background: $primary-brand-1;
}

.btn.el-btn__inform_disabled:before {
  background: #d3d8e5;
}
.btn.el-btn__inform_disabled:hover:before {
  background: #d3d8e5;
}

.btn.el-btn__inform {
  width: 40px;
  height: 40px;
  padding: 8px;
  position: relative;
  background-color: #f8f9fb;
}
.item-list__document-additions {
  background-color: #f8f9fb;
  padding: 20px 20px 20px 50px;
  margin-top: 10px;
}
.item-list__document-additions-item {
  display: flex;
  padding: 10px 0;

  .item-list__document-additions-item-date {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #6b7a99;
    width: 180px;
    border-right: 1px solid #edeff3;
    margin-right: 20px;
  }

  .item-list__document-additions-item-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0050cf;
  }
}
.item-list__document-additions {
  .item-list__content-tag {
    display: inline-block;
    border: 1px solid $color-gray-dusk-4;
    border-radius: 4px;
    padding: 6px 8px 6px 8px;

    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: $color-gray-dusk;
  }
}
.item-list__document-info {
  display: flex;
  align-items: center;
  position: relative;

  .item-list__number {
    border-right: 1px solid #edeff3;
    width: 180px;
    flex: 0 0 180px;
    overflow: hidden;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $base-black-color;
      margin-bottom: 12px;
    }
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #6b7a99;
    }
  }
  .item-list__content {
    padding-left: 24px;
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $blue-color;
      display: inline-block;
      margin: 5px 0 0 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $base-black-color;
      margin-bottom: 12px;
    }
    &-tag {
      display: inline-block;
      border: 1px solid $color-gray-dusk-5;
      border-radius: 4px;
      padding: 6px 8px 6px 8px;
      margin-left: 8px;

      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: $color-gray-dusk;
    }
  }
  .item-list__extension {
    width: 50px;
  }
  .item-list__extension-btn {
    width: 40px;
    height: 40px;
    border-color: #6b7a99;
    border-width: 1px;
    border-radius: 50px;
  }
}
.item-list__document {
  padding: 20px 0;
}
.item-list__document:not(:last-child) {
  border-bottom: 1px solid #edeff3;
}
.item-list__document:first-child {
  padding-top: 0;
}
.item-list__document:last-child {
  padding-bottom: 0;
}

.container-list {
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  padding: 24px;
}
.title-number__list {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7a99;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .data-number {
    flex: 0 0 50px;
    margin-right: 63px;
    margin-left: 20px;
  }
}
.acts-component .title-number__list .data-number {
  flex: 0 0 190px;
  padding-left: 48px;
}
.acts-component__dialog-payment .el-dialog {
  width: 520px;
  .el-dialog__title {
    font-size: 24px;
    font-weight: 600;
  }
}
.link-btn-upload {
  min-width: 44px;
  height: 44px;
  background-color: #f8f9fb;
  border-radius: 4px;
  border: unset;
  cursor: pointer;
  display: block;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 11px);
    width: 16px;
    height: 16px;
    background: url('../../assets/icons/icon-clip-add-g.svg') 100% 100%
      no-repeat;
  }
  &.scancopy {
    margin-left: 4px;
    &:before {
      background: url('@/assets/icons/sign-act-icon.svg') 100% 100%;
      top: calc(50% - 8px);
      left: calc(50% - 7px);
    }
  }
}
.item-list__buttons {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  display: flex;
  .button {
    min-width: 44px;
    height: 44px;
    background-color: #f8f9fb;
    border-radius: 4px;
    border: unset;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 9px);
      width: 20px;
      height: 19px;
      background: url('@/assets/icons/icon-eye-open.svg') 100% 100% no-repeat;
    }
    &__payment-info {
      margin-left: 4px;
      margin-right: 4px;
    }
    &__payment-info:first-child {
      margin-left: 0px;
    }
    &__payment-info:last-child {
      margin-right: 0px;
    }
    &__payment-info:before {
      background: url('@/assets/icons/icon-pay-card.svg') 100% 100% no-repeat;
    }
  }
  .link-btn-download {
    min-width: 44px;
    height: 44px;
    background-color: #f8f9fb;
    border-radius: 4px;
    border: unset;
    cursor: pointer;
    display: block;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 9px);
      width: 16px;
      height: 16px;
      background: url('../../assets/icons/icon-download-file.svg') 100% 100%
        no-repeat;
    }
  }
  /*  .link-btn-upload {
    width: 44px;
    height: 44px;
    background-color: #f8f9fb;
    border-radius: 4px;
    border: unset;
    cursor: pointer;
    display: block;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 11px);
      width: 16px;
      height: 16px;
      background: url('../../assets/icons/icon-clip-add-g.svg') 100% 100%
        no-repeat;
    }
    &.scancopy {
      margin-left: 4px;
      &:before {
        background: url('@/assets/icons/sign-act-icon.svg') 100% 100%;
        top: calc(50% - 8px);
        left: calc(50% - 7px);
      }
    }
  }*/

  .link-btn-share {
    background-color: #f8f9fb;
    color: $blue-color;
    border-radius: 4px;
    padding: 10px 35px;
    height: 44px;
    margin-left: 4px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $blue-color;
      color: #fff;
    }
  }
}

// media
@media (max-width: 1440px) {
  .container-list {
    .item-list__document-info {
      padding-right: 220px;
      position: relative;
    }
  }
}

@media (max-width: 920px) {
  .container-list {
    background-color: transparent;
    box-shadow: unset;
    padding: 0;
    .item-list__document-additions {
      padding: 20px;
    }
    .item-list__document {
      background-color: #ffffff;
      box-shadow: 0 1px 2px rgb(106 122 152 / 15%);
      border-radius: 4px;
      padding: 24px 20px;
      margin-bottom: 8px;
      flex-direction: column;
      &-info {
        flex-direction: column;
        position: relative;
        padding-right: 0px;
      }
      .item-list__content {
        margin-top: 10px;
        padding: 0;
        width: 100%;
        span {
          margin: 0 0 4px 0;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }
      .item-list__number {
        padding: 0 32px 16px 0;
        border-right: unset;
        border-bottom: 1px solid #edeff3;
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        align-items: center;
        p {
          margin: 0 8px 0 0;
        }
      }
      .item-list__buttons {
        width: 100%;
        position: static;
      }
      .item-list__extension {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
      }
      .item-list__extension-btn {
        width: 32px;
        height: 32px;
      }
      .item-list__document-additions-item-date {
        width: 120px;
      }
    }
  }
}

@media (max-width: 640px) {
  .acts-component__dialog-payment .el-dialog {
    width: 100%;
  }
}
</style>
