const ALL = 'all';
const STUDIES = 'studies';
const COMPLETED = 'completed';
const PAYMENT = 'payment';
const PROCESSING = 'processing';
const WAITING = 'waiting';
const OTHER = 'other';
const DISMISS = 'dismiss';
const CANCELED = 'canceled';
const DENIED = 'denied';
const REVOKE = 'revoke';

export const PROGRAM_STATUS = {
  ALL,
  STUDIES,
  COMPLETED,
  PAYMENT,
  PROCESSING,
  WAITING,
  OTHER,
  DISMISS,
  CANCELED,
  DENIED,
  REVOKE
};

export const PROGRAM_STATUS_COLOR = {
  [ALL]: '#0050cf',
  [STUDIES]: '#00a35f',
  [COMPLETED]: '#0f0f14',
  [PAYMENT]: '#eB6d00',
  [PROCESSING]: '#8f5714',
  [WAITING]: '#581add',
  [OTHER]: '#0f0f14',
  [DISMISS]: '#0f0f14',
  [CANCELED]: '#0f0f14',
  [DENIED]: '#0f0f14',
  [REVOKE]: '#D82E2E'
};

export const PROGRAM_STATUS_ICON_COLOR = {
  [ALL]: '#0050cf',
  [STUDIES]: '#00a35f',
  [COMPLETED]: '#0f0f14',
  [PAYMENT]: '#eB6d00',
  [PROCESSING]: '#cd8703',
  [WAITING]: '#581add',
  [OTHER]: '#cfcfcf',
  [DISMISS]: '#0f0f14',
  [CANCELED]: '#0f0f14',
  [DENIED]: '#0f0f14',
  [REVOKE]: '#D82E2E'
};

export function getColorByStatus(status) {
  if (Object.prototype.hasOwnProperty.call(PROGRAM_STATUS_COLOR, status)) {
    return PROGRAM_STATUS_COLOR[status];
  }

  return PROGRAM_STATUS_COLOR[OTHER];
}

export function getIconColorByStatus(status) {
  if (Object.prototype.hasOwnProperty.call(PROGRAM_STATUS_ICON_COLOR, status)) {
    return PROGRAM_STATUS_ICON_COLOR[status];
  }

  return PROGRAM_STATUS_ICON_COLOR[OTHER];
}
