const getFaq = 'get-faq';
const getFaqByName = 'get-faq-by-name';
const SEND_FEEDBACK = 'send-feedback';
const GET_FAQ = 'get-faq';
const GET_FAQ_BY_NAME = 'get-faq-by-name';
const CLAIMS = 'claims';
const SEND_EMAIL_CODE = 'send-email-code';
const REGISTRATION = 'registration';
const SEND_PEP_CODE_PASSWORD_RESET = 'send-pep-code-password-reset';
const SEND_EMAIL_CODE_PASSWORD_RESET = 'send-email-code-password-reset';
const RESET_PASSWORD = 'reset-password';
const SEND_NEW_PASSWORD = 'setNewPassword';
const REGISTRATION_STEP_1 = 'registration-step-1';
const REGISTRATION_STEP_2 = 'registration-step-2';
const REGISTRATION_STEP_3 = 'registration-step-3';
const GET_FOOTER = 'get-footer';
const LOGOUT = 'logout';
const GET_USER = 'get-user';
const GET_LEARNERS = 'get-learners';
const SET_LOCALE = 'set-locale';
const GET_STAFF_INFO_BY_EMAIL = 'get-staff-info-by-email';
const GET_PHOTO_BY_EMAIL = 'get-photo-by-email';
const GET_SERVICES_BY_NAME = 'get-services-by-name';
const GET_STAFF_BY_NAME = 'get-staff-by-name';
const SET_MY_CONTACT = 'set-mycontact';
const DELETE_MY_CONTACT = 'delete-mycontact';
const GET_UNITS_BY_NAME = 'get-units-by-name';
const TASKS = 'tasks';
const GET_NOTIFICATION_COUNT = 'get-notification-count';
const GET_STUDDOG_STEP = 'get-studdog-step';
const SET_STUDDOG_STEP = 'set-studdog-step';
const SET_STUDDOG_OFFLINE = 'set-studdog-offline';
const SEND_PEP_SMS_CODE = 'send-pep-sms-code';
const SET_WIDGET_SETTINGS = 'set-widget-settings';
const GET_MYSCHEDULE_SOON = 'get-myschedule-soon';
const GET_UNIT_TREE = 'get-unit-tree';
const GET_UNITS_BY_PARENT = 'get-units-by-parent';
const SET_EMAIL_PERSONAL = 'set-email-personal';
const SET_EMAIL_VISIBLE = 'set-email-visible';
const SET_PHONE_VISIBLE = 'set-phone-visible';
const CHANGE_LOST_PEP_STEP1 = 'change-lost-pep-step1';
const CHANGE_LOST_PEP_STEP2 = 'change-lost-pep-step2';
const CHANGE_PEP_PHONE = 'change-pep-phone';
const RESET_PASSWORD_AUTH = 'reset-password-auth';
const CHANGE_PEP_STEP1 = 'change-pep-step1';
const CHANGE_PEP_STEP2 = 'change-pep-step2';
const GET_MYSCHEDULE = 'get-myschedule';
const GET_SERVICE = 'get-service';
const GET_MY_BANNER_NEWS = 'get-my-banner-news';
const GET_MY_NEWS = 'get-my-news';
const GET_ONE_MY_NEWS = 'get-one-my-news';
const GET_STAFF_INFO = 'get-staff-info';
const GET_STAFF_BY_NAME_UNIT = 'get-staff-by-name-unit';
const SET_BIRTHDATE_DATE_SHOW_TYPE = 'set-birth-date-show-type';
const GET_PROFILE = 'get-profile';
const DELETE_MYSERVICE = 'delete-myservice';
const DELETE_USER_SERVICE = 'delete-user-service';
const SET_MYSERVICE = 'set-myservice';
const SET_USER_SERVICE = 'set-user-service';
const GET_MYCONTACTS = 'get-mycontacts';
const GET_MYSERVICES = 'get-myservices';
const GET_MYSERVICES2 = 'get-myservices2';
const GET_POPULAR_SERVICES = 'get-popular-service';
const GET_SERVICES_BY_NAME_LOCAL = 'get-services-by-name-local';
const CHANGE_PASSWORD = 'reset-password-auth';
const SET_AVATAR = 'set-avatar';
const GET_WORK_ADDRESS_DICTS = 'get-work-address-dicts';
const SET_USER_WORK_ADDRESS = 'set-user-work-address';
const DELETE_USER_WORK_ADDRESS = 'delete-user-work-address';
const SET_NOTIFICATION_READ = 'set-notification-read';
const NOTIFICATIONS = 'notifications';
const SET_PHONE_MOBILE_STEP1 = 'set-phone-mobile-step1';
const SET_PHONE_MOBILE_STEP2 = 'set-phone-mobile-step2';
const GET_TOP_NOTIFICATIONS = 'get-top-notifications';
const GET_PHONE_FOR_SMS = 'get-phone-for-sms';
const GET_STAT_INDEXES = 'get-stat-indexes';
const GET_WELCOME_TEXT = 'get-welcome-text';
const GET_OLIMP_INFO = 'get-olimp-info';
const SET_OLIMPIAD_PARTICIPANT = 'set-olympiad-participant';
const DELETE_OLYMPIAD_PARTICIPANT = 'delete-olympiad-participant';
const GET_EXPB_DICTIONARIES = 'get-expb-dictionaries';
const SEND_EXPB_FORM = 'send-expb-form';
const GET_GPH_DOCS = 'get-gph-docs';
const SIGN_GHP_DOC_STEP1 = 'sign-gph-doc-step1';
const CANCEL_GPH_DOC = 'cancel-gph-doc';
const SIGN_GHP_DOC_STEP2 = 'sign-gph-doc-step2';
const RESET_PASSWORD_STEP1 = 'reset-password-step1';
const RESET_PASSWORD_STEP2 = 'reset-password-step2';
const RESET_PASSWORD_STEP3 = 'reset-password-step3';
const GET_OLIMP_INFO2 = 'get-olimp-info2';
const SET_OLYMPIAD_PARTICIPANT2 = 'set-olympiad-participant2';
const DELETE_OLYMPIAD_PARTICIPANT2 = 'delete-olympiad-participant2';
const GET_ACTION_HISTORY_RECORDS = 'get-action-history-records';
const GET_ACTION_HISTORY_RECORD_COUNT = 'get-action-history-record-count';
const GET_ACTION_HISTORY_DEVICE_TYPES = 'get-action-history-device-types';
const GET_ACTIVITY = 'get-activity';
const SET_ACTIVITY_ROLE = 'set-activity-role';
const GET_COUNTRIES = 'get-countries';
const SET_NATIONALITY = 'set-nationality';
const GET_GENDERS = 'get-genders';
const GET_ADFS_ENTRY_POINT = 'get-adfs-entry-point';
const SET_GENDER = 'set-gender';
const ADD_FILE = 'add-file';
const GET_ACTIVITY_WELCOME = 'get-activity-welcome';
const CHECK_USER_BY_EMAIL = 'check-user-by-email';
const REJECT_STUDDOG = 'reject-studdog';
const SET_STUDDOG_VIEWED = 'set-studdog-viewed';
const GET_PROFILE_ADM = 'get-profile-adm';
const GET_PROFILE_STUD = 'get-profile-stud';
const GET_ADDRESSES_PSYCHOLOGIST = 'get-addresses-psychologist';
const GET_FREEDATES_PSYCHOLOGIST = 'get-freedates-psychologist';
const GET_SPECIALISTS_PSYCHOLOGIST = 'get-specialists-psychologist';
const GET_TIMESLOTS_PSYCHOLOGIST = 'get-timeslots-psychologist';
const SET_FORM_PSYCHOLOGIST = 'set-form-psychologist';
const GET_PROFILE_WORKPLACE = 'get-profile-workplace';
const GET_PROFILE_HSE_EDUCATION = 'get-profile-hse-education';
const SET_PROFILE_BIRTHDATE = 'set-profile-birthdate';
const SET_COVID_LOGIN_BLOCK = 'set-covid-login-block';
const SIGN_GHP_DOC_STEP2_NEW = 'sign-gph-doc-step2-new';
const GET_GPH_FILES = 'get-gph-files';
const GET_GPH_DOCS_NEW = 'get-gph-docs-new';
const CANCEL_GPH_DOC_NEW = 'cancel-gph-doc-new';
const GET_POSTGRADUATE_CONFERENCES =
  'postgraduate/get-postgraduate-conferences';
const SET_POSTGRADUATE_CONFERENCE = 'postgraduate/set-postgraduate-conference';
const GET_POSTGRADUATE_CONFERENCE = 'postgraduate/get-postgraduate-conference';
const DELETE_POSTGRADUATE_CONFERENCE =
  'postgraduate/delete-postgraduate-conference';
const GET_CONFERENCE_PARTICIPATION_TYPE =
  'postgraduate/get-conference-participation-type';
const DOC_ADD_FILE = 'doc/add-file';
const GET_POSTGRADUATE_SCHOOL_TIMETABLE_CLASSES =
  'postgraduate/get-postgraduate-school-timetable-classes';
const GET_POSTGRADUATE_PUBLICATIONS =
  'postgraduate/get-postgraduate-publications';
const GET_APPLICATIONS = 'postgraduate/get-applications';
const GET_DOCUMENT_STATUSES = 'doc/get-document-statuses';
const GET_DISEASE = 'get-disease';
const SET_DISEASE = 'set-disease';
const CREATE_DOC = 'doc/create-doc';
const GET_BODY_CHECK_USER_INFO = 'get-body-check-user-info';
const GET_BODY_CHECK_REQUEST_INFO = 'get-body-check-request-info';
const SET_BODY_CHECK_REQUEST = 'set-body-check-request';
const GET_BODY_CHECK_REQUEST_FILE = 'get-body-check-request-file';
const GET_VALIDATION_LISTS = 'postgraduate/get-validation-lists';
const GET_VALIDATION = 'postgraduate/get-validation';
const GET_TASK_LIST = 'postgraduate/get-task-list';
const GET_ABSENCE = 'get-absence';
const GET_ACADEMIC_DEBT = 'postgraduate/get-academic-debt';
const GET_ATTESTATION_RESULT = 'postgraduate/get-attestation-result';
const GET_INTERNSHIP_PRACTICE_ELEMENTS =
  'postgraduate/get-internship-practice-elements';
const GET_PRACTICE_TYPE = 'postgraduate/get-practice-type';
const GET_PORTAL_PUBLICATIONS = 'postgraduate/get-portal-publications';
const GET_INTERNSHIP_AND_RESEARCH_REPORTS =
  'postgraduate/get-internship-and-research-reports';
const GET_DICTIONARY_BY_SIGNATURE = 'postgraduate/get-dictionary-by-signature';
const GET_FILE_TEMPORARY_LINK = 'get-file-temporary-link';
const SET_INTERNSHIP_AND_RESEARCH_REPORT =
  'postgraduate/set-internship-and-research-report';
const GET_INTERNSHIP_AND_RESEARCH_REPORT =
  'postgraduate/get-internship-and-research-report';
const GET_VALIDATION_PERIODS = 'postgraduate/get-validation-periods';
const SET_DOCUMENT_DELETION_FLAG = 'postgraduate/set-document-deletion-flag';
const DELETE_INTERNSRHIP_AND_RESEARCH_REPORT =
  'postgraduate/delete-internship-and-research-report';
const GET_CLAIMS = 'kedo/get-claims';
const GET_POSITIONS = 'kedo/get-positions';
const KEDO_GET_STAFF_BY_NAME = 'kedo/get-staff-by-name';
const SEND_CODE = 'kedo/send-code';
const CHECK_CODE_AND_SIGN = 'kedo/check-code-and-sign';
const KEDO_GET_USER_INFO = 'kedo/get-user-info';
const KEDO_GET_DAYS_COUNT = 'kedo/get-days-count';
const KEDO_GET_TEXT_CAUSE = 'kedo/get-text-cause';
const KEDO_GET_VACATION = 'kedo/get-vacation';
const KEDO_GET_PARENTAL_LEAVE = 'kedo/get-parental-leave';
const KEDO_GET_STATUS_SEV_KEDO = 'kedo/get-status-sev-kedo';
const KEDO_GET_ONE_DOC = '/kedo/get-one-doc';
const DOC_GET_DOC = 'doc/get-doc';
const DOC_CREATE_DOC = 'doc/create-doc';
const KEDO_GET_POSITIONS = 'kedo/get-positions';
const DOC_SET_DOC = 'doc/set-doc';
const KEDO_REGISTER_DOCUMENT = 'kedo/register-document';
const DOC_SEND_DOC = 'doc/send-doc';
const KEDO_GET_ORDER_LIST = 'kedo/get-order-list';
const KEDO_CHECK_VACATION_INTERSECTION = 'kedo/check-vacation-intersections';
const STRAPI_SERVICE_DETAILS = '/get-service-from-str';
const STRAPI_IT_SERVICE_DETAILS = '/get-it-service-from-str';
const GET_USER_INFO_BY_EMAIL = '/get-user-info-by-email-with-base-auth';
const GET_OFFERED_PROGRAMS = 'get-offered-programs';
const COMPARISON_PROGRAM = '/dpo/rest/compares';
const COMPARISON_PROGRAM_ADD = '/dpo/rest/compares/add';
const COMPARISON_PROGRAM_REMOVE = '/dpo/rest/compares/remove';
const FAVORITE_PROGRAM = '/dpo/rest/favorites';
const FAVORITE_PROGRAM_ADD = '/dpo/rest/favorites/add';
const FAVORITE_PROGRAM_REMOVE = '/dpo/rest/favorites/remove';

const FAVORITE_PROGRAM_CLEAR = '/dpo/rest/favorites/clear';
const GET_ADDITIONAL_PAGE_TREE = '/dpo/rest/navigations';
const GET_BROWSING_HISTORY = '/dpo/rest/browsing-history';
const GET_DOCUMENT = '/dpo/rest/document';
const GET_DOCUMENT_REFERENCES = '/dpo/rest/document/references';
const GET_PROFILE_REFERENCES = '/dpo/rest/profile/references';
const GET_REF_REGIONS = '/dpo/rest/profile/regions';
const GET_DOCUMENT_FIELDS_RULES = '/dpo/rest/document/fields-rules';
const GET_RECOMMENDED_PROGRAMS = '/dpo/rest/programs/recommendations';
const GET_PROGRAM_SPECIALIZATIONS = programId =>
  `/dpo/rest/programs/${programId}/specializations`;
const GET_SELECTED_PROGRAMS = '/dpo/rest/selected-programs';
const GET_PF = '/dpo/rest/pf/';
const GET_PF_EI = '/dpo/rest/pf/ei';
const GET_PF_PD = '/dpo/rest/pf/pd';
const PROFILE_V1 = '/dpo/rest/profile';
const REF_TEACHER_CAT = '/dpo/rest/profile/ref-teacher-cat';
const PROFILE_TAGS = '/dpo/rest/profile/tags';
const V1_CHECK_EMAIL = '/dpo/rest/check-email?email=';
const V1_REGISTRATION_STEP_1 = 'new-registration-step-1'; /* TODO: fix url */
const V1_REGISTRATION_STEP_2 = 'new-registration-step-2'; /* TODO: fix url */
const REPRESENT_REGISTRATION = '/dpo/rest/legal/registration';
const REPRESENT_PROFILE_EDIT = '/dpo/rest/legal/represents/profile';
const REPRESENT_PROFILE_LEGAL_EDIT = '/dpo/rest/legal/entity';
const REGISTRATION_INN_EXISTS = '/dpo/rest/legal/entity/exists?inn=';
const FETCH_REPRESENT_PROFILE = '/dpo/rest/legal/represents/profile';
const FETCH_REPRESENT_LEGAL_PROFILE = '/dpo/rest/legal/entity';
const FETCH_REPRESENT_SERVICE_LIST = '/dpo/rest/service';
const FETCH_REPRESENT_LEGAL_LISTENERS = '/dpo/rest/legal/listeners';

const REPRESENT_DOCUMENT_LIST_ATTACH_BILL = documentId =>
  `/dpo/rest/legal/contracts/${documentId}/attach-check`;
const REPRESENT_DOCUMENT_LIST_ATTACH_SCAN = documentId =>
  `/dpo/rest/legal/contracts/${documentId}/attach-scan`;

const FETCH_CONTRACTS = '/dpo/rest/legal/contracts';
const REPRESENT_LEGAL_LISTENER_EDUCATION_DOCUMENT = listenerId =>
  `/dpo/rest/legal/listeners/${listenerId}/education-document`;
const FETCH_CONTRACT_LISTENERS = contractId =>
  `/dpo/rest/legal/contracts/${contractId}/listeners`;

const FETCH_REPRESENT_DOCUMENTS_ACTS = '/dpo/rest/legal/documents/acts';
const ATTACH_SCAN_TO_ACT = actId =>
  `/dpo/rest/legal/documents/acts/${actId}/sign`;
const FETCH_REPRESENT_DOCUMENTS_BILLS = '/dpo/rest/legal/documents/invoices';
const ATTACH_SCAN_TO_BILL = billId =>
  `/dpo/rest/legal/documents/invoices/${billId}/attach-scan`;
const ADD_INVITE_REPRESENT = '/dpo/rest/legal/represents';
const FETCH_REPRESENT_LIST = '/dpo/rest/legal/represents';
const REPRESENT_LIST_REMOVE_USER = '/dpo/rest/legal/represents';
const REPRESENT_SWITCH_ADMIN_ROLE =
  '/dpo/rest/legal/represents/transfer-admin-role';
const REPRESENT_TRANSFER_ADMIN_ROLE =
  '/dpo/rest/legal/represents/transfer-admin-role';
const FETCH_REPRESENT_CONTRACTS = '/dpo/rest/legal/contracts';
const FETCH_REPRESENT_INVITE_DATA = '/dpo/rest/legal/represents/invite';
const FETCH_LISTENER_INVITE_DATA = '/dpo/rest/legal/listeners/invite';
const FETCH_BITVUZ_INVITE_DATA = '/dpo/rest/bitvuz-invite-user';
const LISTENER_INVITE_EXIST_USER = '/dpo/rest/legal/listeners/invite/exist';
const SEND_LISTENER_INVITE_DATA = '/dpo/rest/legal/listeners/invite';
const SEND_REPRESENT_INVITE_DATA = '/dpo/rest/legal/represents/invite';
const FETCH_REPRESENT_ADMIN = '/dpo/rest/legal/represents/admin';
const ADD_REPRESENT_LEGAL_LISTENER = '/dpo/rest/legal/listeners';
const IMPORT_REPRESENT_LEGAL_LISTENERS = '/dpo/rest/legal/listeners/import';
const IMPORT_REPRESENT_STATUS = '/dpo/rest/legal/listeners/import';
const DELETE_REPRESENT_LEGAL_LISTENER = '/dpo/rest/legal/listeners';
const DELETE_ACCOUNT = '/dpo/rest/delete-account';
const REFERENCES_CONTRACT_CONCLUSION =
  '/dpo/rest/references/contract-conclusion-methods';
const REFERENCES_CONTRACT_STATUSES = '/dpo/rest/references/contract-statuses';
const REFERENCES_CONTRACT_PAYMENT_STATUSES =
  '/dpo/rest/references/contract-payment-statuses';
const REFERENCES_SERVICE_TYPES = '/dpo/rest/references/service-types';
const REFERENCES_SERVICE_STATUSES = '/dpo/rest/references/service-statuses';
const PROGRAMS_SCHEDULE_DATES = '/dpo/rest/legal/programs/schedule-start-dates';
const LEGAL_APPLICATIONS = '/dpo/rest/legal/applications';
const REFERENCES_LANGUAGES = '/dpo/rest/references/languages';
const GET_LEGAL_PROGRAMS = '/dpo/rest/legal/programs';
const GET_CART = '/dpo/rest/cart';
const CLEAR_CART = '/dpo/rest/cart/clear';
const REMOVE_CART = '/dpo/rest/cart/remove';
const ADD_CART = '/dpo/rest/cart/add';
const ORDER_CREATE = '/dpo/rest/orders';
const GET_PAYMENTS = '/dpo/rest/orders/payment-methods';
const GET_CONTRAGENT_TYPES = '/dpo/rest/contracts/contragent-types';
const COMPARES_INDEX = '/dpo/rest/compares';
const COMPARES_ADD = '/dpo/rest/compares/add';
const COMPARES_REMOVE = '/dpo/rest/compares/remove';
const COMPARES_CLEAR = '/dpo/rest/compares/clear';
const GET_RECEIPT = '/dpo/rest/orders/receipt';
const ORDER_PAY = '/dpo/rest/orders/pay';
const GET_SERVICES = '/dpo/rest/service';
const GET_CORP_SERVICES = '/dpo/rest/service/corp';
const GET_HELP_PROGRAM_FROM = '/dpo/rest/service';
const GET_HELP_SERVICE_CORP = '/dpo/rest/service/manager-legal';
const SEND_REVIEW = '/dpo/rest/feedback';
const REFERENCES_BASIS_DOCUMENTS = '/dpo/rest/references/basis-documents';
const REFERENCES_CAMPUSES = '/dpo/rest/references/campuses';
const REFERENCES_GENDERS = '/dpo/rest/references/genders';
const REFERENCES_PROGRAM_TYPES = '/dpo/rest/references/program-types';
const REFERENCES_SPECIALIZATIONS = '/dpo/rest/references/specializations';
const REFERENCES_TRAINING_AREAS = '/dpo/rest/references/training-areas';
const REFERENCES_COUNTRIES = '/dpo/rest/references/countries';
const REFERENCES_IDENTITY_DOC_TYPES =
  '/dpo/rest/references/identity-document-types';
const REFERENCES_CONTRACT_TYPES = '/dpo/rest/references/contract-types';
const REFERENCES_CONTRACT_TYPES_LEGAL =
  '/dpo/rest/references/contract-types-legal';

const GET_SERVICE_FORM = '/dpo/rest/service/form';
const GET_SERVICE_REQUESTS = '/dpo/rest/service/requests';
const GET_SCHEDULE = '/dpo/rest/personal-events';
export const GET_SCHEDULE_EXPORT_ID = '/dpo/rest/personal-events/export-id';
const V1_APPLICATIONS = '/dpo/rest/application';
const GET_SERVICE_MANAGER = '/dpo/rest/service/manager';
const V1_DOCUMENTS = '/dpo/rest/contracts';
const V1_CONTRACTS_PAYMENT = '/dpo/rest/contracts/payment';
const V1_CONTRACTS_SCAN_COPY = '/dpo/rest/contracts/scan-copy';
const V1_CONTRACT_PAYMENTS = '/dpo/rest/contracts/payments';
const V1_DOCUMENTS_FINAL = '/dpo/rest/final';
const V1_DOCUMENTS_FINAL_CORRECTION = '/dpo/rest/final/correction';
const INVOICES = '/dpo/rest/invoices';
const IS_FOREIGNER = '/dpo/rest/document/is-foreigner';
const REF_DEPARTMENTS = '/dpo/rest/references/departments';
const REF_TRAINING_AREAS = '/dpo/rest/references/training-areas';
const REF_FINAL_DOCUMENT_TYPES = '/dpo/rest/references/final-document-types';
const REF_DURATION_TYPES = '/dpo/rest/references/duration-types';
const REF_FOR_WHOM = '/dpo/rest/references/for-whom';
const REF_EDU_FORMATS = '/dpo/rest/references/edu-formats';
const REF_CAMPUSES = '/dpo/rest/references/campuses';
const REF_PROGRAM_TYPES = '/dpo/rest/references/program-types';
const REF_MANAGEMENT_LEVELS = '/dpo/rest/references/management-levels';
const REF_CUSTOM_SERVICE = '/dpo/rest/references/represent-custom-service';
const V1_PROFILE_FILL_CHECK = '/dpo/rest/profile-fill-check';
const CHECK_ONLINE_PAYMENT_STATUS = '/dpo/rest/orders/payment-status';
const FETCH_REFERENCES_BASIC_DOCUMENTS = '/dpo/rest/references/basis-documents';
const LEGAL_SUPERVISOR = '/dpo/rest/legal/supervisor';
const INDIVIDUAL_SUPERVISOR = '/dpo/rest/supervisor';
const MAX_FILE_SIZE_TEXT = '10Мб.';
const PAY_SLIP = {
  checkUser: 'payslip/check-user',
  sendCode: 'payslip/send-code',
  checkCode: 'payslip/check-code',
  getPaySlip: 'payslip/get-payslip',
  getDate: 'payslip/get-date',
  getPeriod: 'payslip/get-period'
};
const GET_YA_MESSENGER_URL = 'yandex/get-ya-messenger-url';
const GET_UNITS_BY_NAME_HR = 'get-units-by-name-hr';
const CHECK_EMAIL_CODE = 'check-email-code';

const CAMUNDA = {
  BASE_URL: '/rest-api/camunda-gateway',
  PROCESS_START_FORM: '/forms/process-start-form',
  PROCESS_START: '/process/start',
  TASKS_BY_EMAIL: '/task/find-task-by-user-mail',
  USER_TASK_FORM: '/forms/usertask-form',
  TASK_SUBMIT: '/task/submit',
  TASKS_BY_ID: '/get-contracts-by-id'
};

const GRANTS = 'get-grants';

export {
  INVOICES,
  LEGAL_SUPERVISOR,
  INDIVIDUAL_SUPERVISOR,
  FETCH_CONTRACT_LISTENERS,
  FETCH_CONTRACTS,
  FETCH_REPRESENT_DOCUMENTS_ACTS,
  FETCH_BITVUZ_INVITE_DATA,
  ATTACH_SCAN_TO_ACT,
  GET_PF,
  LEGAL_APPLICATIONS,
  REFERENCES_LANGUAGES,
  REFERENCES_SERVICE_TYPES,
  REFERENCES_SERVICE_STATUSES,
  REFERENCES_CONTRACT_CONCLUSION,
  REF_CUSTOM_SERVICE,
  PROGRAMS_SCHEDULE_DATES,
  GET_CORP_SERVICES,
  FETCH_REPRESENT_DOCUMENTS_BILLS,
  ATTACH_SCAN_TO_BILL,
  IMPORT_REPRESENT_STATUS,
  GET_LEGAL_PROGRAMS,
  DELETE_REPRESENT_LEGAL_LISTENER,
  DELETE_ACCOUNT,
  IMPORT_REPRESENT_LEGAL_LISTENERS,
  ADD_REPRESENT_LEGAL_LISTENER,
  FETCH_REPRESENT_LEGAL_LISTENERS,
  LISTENER_INVITE_EXIST_USER,
  REPRESENT_LEGAL_LISTENER_EDUCATION_DOCUMENT,
  SEND_REVIEW,
  DOC_ADD_FILE,
  GET_CONFERENCE_PARTICIPATION_TYPE,
  GET_POSTGRADUATE_CONFERENCES,
  SET_POSTGRADUATE_CONFERENCE,
  GET_POSTGRADUATE_CONFERENCE,
  DELETE_POSTGRADUATE_CONFERENCE,
  GET_POSTGRADUATE_SCHOOL_TIMETABLE_CLASSES,
  CREATE_DOC,
  GET_POSTGRADUATE_PUBLICATIONS,
  GET_PROFILE_HSE_EDUCATION,
  GET_REF_REGIONS,
  GET_PROFILE_WORKPLACE,
  SET_STUDDOG_VIEWED,
  REJECT_STUDDOG,
  GET_ADFS_ENTRY_POINT,
  SET_GENDER,
  CHECK_USER_BY_EMAIL,
  GET_ACTIVITY_WELCOME,
  SET_NATIONALITY,
  GET_GENDERS,
  GET_COUNTRIES,
  RESET_PASSWORD_STEP1,
  RESET_PASSWORD_STEP2,
  RESET_PASSWORD_STEP3,
  SEND_EXPB_FORM,
  GET_EXPB_DICTIONARIES,
  SIGN_GHP_DOC_STEP2,
  CANCEL_GPH_DOC,
  SIGN_GHP_DOC_STEP1,
  GET_GPH_DOCS,
  SET_OLIMPIAD_PARTICIPANT,
  NOTIFICATIONS,
  GET_STAT_INDEXES,
  SET_NOTIFICATION_READ,
  GET_TOP_NOTIFICATIONS,
  SET_PHONE_MOBILE_STEP2,
  SET_PHONE_MOBILE_STEP1,
  GET_SERVICES_BY_NAME_LOCAL,
  GET_MYSERVICES,
  GET_MYSERVICES2,
  GET_POPULAR_SERVICES,
  GET_MYCONTACTS,
  SET_MYSERVICE,
  SET_USER_SERVICE,
  DELETE_MYSERVICE,
  DELETE_USER_SERVICE,
  GET_PROFILE,
  SET_BIRTHDATE_DATE_SHOW_TYPE,
  GET_STAFF_BY_NAME_UNIT,
  GET_STAFF_INFO,
  GET_MY_NEWS,
  GET_ONE_MY_NEWS,
  GET_MY_BANNER_NEWS,
  GET_SERVICE,
  GET_MYSCHEDULE,
  CHANGE_PEP_STEP2,
  CHANGE_PEP_STEP1,
  RESET_PASSWORD_AUTH,
  CHANGE_PEP_PHONE,
  CHANGE_LOST_PEP_STEP2,
  CHANGE_LOST_PEP_STEP1,
  SET_PHONE_VISIBLE,
  SET_EMAIL_PERSONAL,
  SET_EMAIL_VISIBLE,
  GET_UNITS_BY_PARENT,
  GET_UNIT_TREE,
  GET_MYSCHEDULE_SOON,
  SET_WIDGET_SETTINGS,
  getFaq,
  getFaqByName,
  SEND_FEEDBACK,
  GET_FAQ,
  GET_FAQ_BY_NAME,
  SEND_EMAIL_CODE,
  REGISTRATION,
  SEND_PEP_CODE_PASSWORD_RESET,
  SEND_EMAIL_CODE_PASSWORD_RESET,
  RESET_PASSWORD,
  SEND_NEW_PASSWORD,
  REGISTRATION_STEP_1,
  REGISTRATION_STEP_2,
  REGISTRATION_STEP_3,
  GET_FOOTER,
  CLAIMS,
  LOGOUT,
  GET_USER,
  GET_ADDITIONAL_PAGE_TREE,
  GET_LEARNERS,
  SET_LOCALE,
  GET_STAFF_INFO_BY_EMAIL,
  GET_PHOTO_BY_EMAIL,
  GET_SERVICES_BY_NAME,
  GET_STAFF_BY_NAME,
  SET_MY_CONTACT,
  DELETE_MY_CONTACT,
  GET_UNITS_BY_NAME,
  TASKS,
  GET_NOTIFICATION_COUNT,
  GET_STUDDOG_STEP,
  SET_STUDDOG_STEP,
  SET_STUDDOG_OFFLINE,
  SEND_PEP_SMS_CODE,
  CHANGE_PASSWORD,
  SET_AVATAR,
  GET_WORK_ADDRESS_DICTS,
  SET_USER_WORK_ADDRESS,
  DELETE_USER_WORK_ADDRESS,
  GET_PHONE_FOR_SMS,
  GET_WELCOME_TEXT,
  GET_OLIMP_INFO,
  DELETE_OLYMPIAD_PARTICIPANT,
  GET_OLIMP_INFO2,
  SET_OLYMPIAD_PARTICIPANT2,
  DELETE_OLYMPIAD_PARTICIPANT2,
  GET_ACTION_HISTORY_DEVICE_TYPES,
  GET_ACTION_HISTORY_RECORD_COUNT,
  GET_ACTION_HISTORY_RECORDS,
  GET_ACTIVITY,
  SET_ACTIVITY_ROLE,
  ADD_FILE,
  GET_PROFILE_ADM,
  GET_PROFILE_STUD,
  GET_ADDRESSES_PSYCHOLOGIST,
  GET_FREEDATES_PSYCHOLOGIST,
  GET_SPECIALISTS_PSYCHOLOGIST,
  GET_TIMESLOTS_PSYCHOLOGIST,
  SET_FORM_PSYCHOLOGIST,
  SET_PROFILE_BIRTHDATE,
  SET_COVID_LOGIN_BLOCK,
  SIGN_GHP_DOC_STEP2_NEW,
  GET_GPH_FILES,
  GET_GPH_DOCS_NEW,
  CANCEL_GPH_DOC_NEW,
  GET_APPLICATIONS,
  GET_DOCUMENT_STATUSES,
  GET_DISEASE,
  SET_DISEASE,
  GET_BODY_CHECK_USER_INFO,
  SET_BODY_CHECK_REQUEST,
  GET_BODY_CHECK_REQUEST_INFO,
  GET_BODY_CHECK_REQUEST_FILE,
  GET_VALIDATION_LISTS,
  GET_VALIDATION,
  GET_TASK_LIST,
  GET_ABSENCE,
  GET_ACADEMIC_DEBT,
  GET_ATTESTATION_RESULT,
  GET_INTERNSHIP_PRACTICE_ELEMENTS,
  GET_PRACTICE_TYPE,
  GET_PORTAL_PUBLICATIONS,
  GET_INTERNSHIP_AND_RESEARCH_REPORTS,
  GET_DICTIONARY_BY_SIGNATURE,
  GET_FILE_TEMPORARY_LINK,
  SET_INTERNSHIP_AND_RESEARCH_REPORT,
  GET_INTERNSHIP_AND_RESEARCH_REPORT,
  GET_VALIDATION_PERIODS,
  SET_DOCUMENT_DELETION_FLAG,
  DELETE_INTERNSRHIP_AND_RESEARCH_REPORT,
  GET_CLAIMS,
  GET_POSITIONS,
  KEDO_GET_STAFF_BY_NAME,
  SEND_CODE,
  CHECK_CODE_AND_SIGN,
  KEDO_GET_USER_INFO,
  KEDO_GET_DAYS_COUNT,
  KEDO_GET_TEXT_CAUSE,
  KEDO_GET_VACATION,
  KEDO_GET_PARENTAL_LEAVE,
  KEDO_GET_STATUS_SEV_KEDO,
  KEDO_GET_ONE_DOC,
  DOC_GET_DOC,
  DOC_CREATE_DOC,
  KEDO_GET_POSITIONS,
  DOC_SET_DOC,
  KEDO_REGISTER_DOCUMENT,
  DOC_SEND_DOC,
  KEDO_GET_ORDER_LIST,
  KEDO_CHECK_VACATION_INTERSECTION,
  COMPARISON_PROGRAM,
  COMPARISON_PROGRAM_ADD,
  COMPARISON_PROGRAM_REMOVE,
  FAVORITE_PROGRAM,
  FAVORITE_PROGRAM_ADD,
  FAVORITE_PROGRAM_REMOVE,
  FAVORITE_PROGRAM_CLEAR,
  GET_BROWSING_HISTORY,
  GET_DOCUMENT,
  GET_DOCUMENT_REFERENCES,
  GET_PROFILE_REFERENCES,
  GET_DOCUMENT_FIELDS_RULES,
  GET_OFFERED_PROGRAMS,
  REFERENCES_CONTRACT_TYPES_LEGAL,
  GET_PF_EI,
  GET_PF_PD,
  GET_RECOMMENDED_PROGRAMS,
  GET_PROGRAM_SPECIALIZATIONS,
  GET_SELECTED_PROGRAMS,
  PROFILE_TAGS,
  PROFILE_V1,
  REF_TEACHER_CAT,
  V1_CHECK_EMAIL,
  V1_REGISTRATION_STEP_1,
  V1_REGISTRATION_STEP_2,
  GET_HELP_SERVICE_CORP,
  GET_CART,
  CLEAR_CART,
  REMOVE_CART,
  ADD_CART,
  ORDER_CREATE,
  ORDER_PAY,
  GET_PAYMENTS,
  GET_CONTRAGENT_TYPES,
  GET_RECEIPT,
  COMPARES_INDEX,
  COMPARES_ADD,
  COMPARES_REMOVE,
  COMPARES_CLEAR,
  GET_SERVICES,
  GET_HELP_PROGRAM_FROM,
  GET_SCHEDULE,
  V1_APPLICATIONS,
  V1_CONTRACTS_PAYMENT,
  V1_CONTRACTS_SCAN_COPY,
  V1_CONTRACT_PAYMENTS,
  IS_FOREIGNER,
  V1_DOCUMENTS,
  V1_DOCUMENTS_FINAL,
  V1_DOCUMENTS_FINAL_CORRECTION,
  REF_DEPARTMENTS,
  REF_TRAINING_AREAS,
  REF_FINAL_DOCUMENT_TYPES,
  REF_DURATION_TYPES,
  REF_EDU_FORMATS,
  REF_CAMPUSES,
  REF_PROGRAM_TYPES,
  V1_PROFILE_FILL_CHECK,
  GET_SERVICE_FORM,
  GET_SERVICE_REQUESTS,
  GET_SERVICE_MANAGER,
  CHECK_ONLINE_PAYMENT_STATUS,
  REF_FOR_WHOM,
  STRAPI_SERVICE_DETAILS,
  GET_USER_INFO_BY_EMAIL,
  STRAPI_IT_SERVICE_DETAILS,
  REFERENCES_BASIS_DOCUMENTS,
  REF_MANAGEMENT_LEVELS,
  REFERENCES_CAMPUSES,
  REFERENCES_GENDERS,
  REFERENCES_PROGRAM_TYPES,
  REFERENCES_SPECIALIZATIONS,
  REFERENCES_TRAINING_AREAS,
  REFERENCES_IDENTITY_DOC_TYPES,
  REFERENCES_CONTRACT_TYPES,
  REFERENCES_COUNTRIES,
  REPRESENT_REGISTRATION,
  REGISTRATION_INN_EXISTS,
  FETCH_REPRESENT_PROFILE,
  FETCH_REPRESENT_LEGAL_PROFILE,
  FETCH_REPRESENT_SERVICE_LIST,
  REPRESENT_PROFILE_EDIT,
  REPRESENT_PROFILE_LEGAL_EDIT,
  FETCH_REFERENCES_BASIC_DOCUMENTS,
  ADD_INVITE_REPRESENT,
  FETCH_REPRESENT_LIST,
  REPRESENT_TRANSFER_ADMIN_ROLE,
  FETCH_REPRESENT_ADMIN,
  REPRESENT_LIST_REMOVE_USER,
  FETCH_REPRESENT_INVITE_DATA,
  SEND_REPRESENT_INVITE_DATA,
  FETCH_REPRESENT_CONTRACTS,
  REFERENCES_CONTRACT_STATUSES,
  REFERENCES_CONTRACT_PAYMENT_STATUSES,
  MAX_FILE_SIZE_TEXT,
  REPRESENT_DOCUMENT_LIST_ATTACH_BILL,
  REPRESENT_DOCUMENT_LIST_ATTACH_SCAN,
  FETCH_LISTENER_INVITE_DATA,
  SEND_LISTENER_INVITE_DATA,
  PAY_SLIP,
  REPRESENT_SWITCH_ADMIN_ROLE,
  GET_UNITS_BY_NAME_HR,
  GET_YA_MESSENGER_URL,
  CHECK_EMAIL_CODE,
  CAMUNDA,
  GRANTS
};
